import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import Banner from '@luna-protocol/core/src/components/Banner/Banner';
import LineBreak from '@luna-protocol/core/src/components/LineBreak/LineBreak';
import { Spinner } from '@luna-protocol/core/src/components/Spinner/Spinner';
import isLoggedIn from '@luna-protocol/core/src/utils/isLoggedIn';
import { useGetDealerBranding } from '@luna-protocol/core/src/queries/useGetDealerBranding.ts';

import { AppContext } from '../../AppContext';
import { useGetFlags } from '../../queries/useGetFlags.ts';
import { useGetPricingStatus } from '../../queries/useGetPricingStatus';
import { useStartPricing } from '../../queries/useStartPricing';
import messages from './Processing.messages';
import './Processing.scss';

const Processing = () => {
  const { formatMessage } = useIntl();
  const { loanApplicationID, setPricingStatus, dealerID, setOEMType } = useContext(AppContext);
  const { startPricing, isIdle: readyToStartPricing, isSuccess, isError } = useStartPricing();
  const { data: pricingData, refetch: refetchStatus, isSuccess: hasData } = useGetPricingStatus(loanApplicationID);
  const { data: flags, refetch: refetchFlags } = useGetFlags(loanApplicationID);

  const navigate = useNavigate();

  const { data: dealerBrandingData } = useGetDealerBranding(dealerID || '');

  useEffect(() => {
    if (dealerBrandingData && dealerBrandingData.oem_type) {
      setOEMType(dealerBrandingData.oem_type);
    }
  }, [dealerBrandingData, setOEMType]);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate('/');
    }
    if (readyToStartPricing && loanApplicationID !== undefined) {
      startPricing(loanApplicationID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanApplicationID]); //this is a one time call

  useEffect(() => {
    // ensure pricingData is only called after pricing has begun
    if (isSuccess || isError) {
      refetchStatus();
      refetchFlags();
    }

    if (hasData) {
      if (pricingData.status === 'prequal-approved') {
        setPricingStatus(pricingData);
        navigate('/approval');
      } else if (pricingData.status === 'prequal-manual-review') {
        navigate('/pending');
      } else if (pricingData.status === 'prequal-review') {
        navigate('/under_review');
      } else if (pricingData.status === 'prequal-blocking-flag-raised' && flags?.flags && flags.flags.length > 0) {
        // there are three types of blocking flags: freeze, mismatch, and redacted
        if (flags.flags.some(flag => flag.type === 'redacted')) {
          navigate('/under_review');
        } else if (flags.flags.some(flag => flag.type === 'security_freeze')) {
          navigate('/frozen');
        } else {
          navigate('/confirm_details');
        }
      } else if (pricingData.status === 'error') {
        navigate('/pricing_error');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingData, navigate, flags, refetchFlags, refetchStatus, isSuccess, isError, hasData]);

  return (
    <>
      <Banner>
        <Spinner />
        <LineBreak />
        <p className="processing-message">{formatMessage(messages.processing)}</p>
      </Banner>
    </>
  );
};

export default Processing;
